import NProgress from 'accessible-nprogress';

import '../src/styles/preloader/preloader.scss';

export const onClientEntry = () => {
  const body: HTMLBodyElement | null = document.querySelector('body');
  const progress: HTMLDivElement | null = document.querySelector('.js-preloader-progress');
  const water: any = document.querySelector('#preloader .water-fill');
  NProgress.start();

  const renderLoader = () => {
    if (!progress || !water) return;
    progress.innerHTML = ` ${(NProgress.status * 100).toFixed(0)}%`;
    water.style.y = `${60 - NProgress.status * 70}px`;
  };

  const int = setInterval(() => {
    renderLoader();
  }, 300);

  document.onreadystatechange = () => {
    if (!progress || !water || !body) {
      body?.classList.add('start-animation');
      body?.classList.remove('preloader_active');
      body?.classList.remove('preloader_ready');
      clearInterval(int);

      return;
    }
    if (document.readyState === 'complete') {
      NProgress.done();
      clearInterval(int);
      progress.innerHTML = ' 100%';
      water.style.y = '-10px';
      setTimeout(() => {
        body.classList.add('preloader_ready');
        body.classList.add('start-animation');
      }, 500);
      setTimeout(() => {
        body.classList.remove('preloader_active');
        body.classList.remove('preloader_ready');
      }, 1000);
    }
  };
};
